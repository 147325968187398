@tailwind base;
@tailwind components;
@tailwind utilities;

/* small css for the mobile nav close */
#nav-mobile-btn.close span:first-child {
  transform: rotate(45deg);
  top: 4px;
  position: relative;
  background: #a0aec0;
}

#nav-mobile-btn.close span:nth-child(2) {
  transform: rotate(-45deg);
  margin-top: 0px;
  background: #a0aec0;
}

.pdf-icon {
  color: red;
}

.app {
  font-family: "Poppins", sans-serif;
}

#root {
  max-width: 768px;
  margin: 0 auto;
}

.welcome h1 a,
.welcome h1 a:visited,
.welcome h1 a:active,
.welcome h1 a:hover {
  text-decoration: underline;
  color: white;
  font-weight: 800;
  opacity: 100%;
}

.filters {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.filter-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 20px;
}

.dropdown {
  position: absolute;
  background-color: black;
  top: 100%;
  left: -200px;
  border-radius: 5px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.dropdown label {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  font-weight: normal;
}

.dropdown input[type="radio"] {
  margin-right: 10px;
}
